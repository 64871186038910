import React, { useEffect, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ReactMarkdown from 'react-markdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { GoogleMap, InfoWindow, LoadScript, Marker } from '@react-google-maps/api';

import './LandingPage.scss';
import itTranslationLozalizationImage from '../media/it-translation-localization.jpg';
import landingImage from '../media/landing-image.png';
import lifeSciencesImage from '../media/life-sciences.jpg';
import lawImage from '../media/law.jpg';
import marketingImage from '../media/marketing.jpg';
import technologyImage from '../media/technology.jpg';
import travelHospitalityImage from '../media/travel-hospitality.jpg';

import aaa2015Image from '../media/memberships-and-certifications/AAA2015.jpg';
import eliaImage from '../media/memberships-and-certifications/elia.png';
import euatcImage from '../media/memberships-and-certifications/EUATC-Network.jpg';
import galaImage from '../media/memberships-and-certifications/Gala.png';
import iso17100Image from '../media/memberships-and-certifications/ISO17100.jpg';
import satcImage from '../media/memberships-and-certifications/satc.png';

import quoteTextYml from '../netlify-content/landing-page/quote-text.yml';

const EXPERTISE = [
  {
    title: "IT Translation and Localization",
    text: "IT translation and localization is one of our main areas of expertise.",
    image: itTranslationLozalizationImage
  },
  {
    title: "Life Sciences",
    text: "Medical content needs to be understood without any issues by users and patients across the globe.",
    image: lifeSciencesImage
  },
  {
    title: "Technical/Automotive Translation",
    text: "We have been translating manuals and specifications for a variety of products – from home appliances to complex industrial machines.",
    image: technologyImage
  },
  {
    title: "Legal Translation",
    text: "We specialize in high quality legal translation – translation of contracts, privacy policies, court documents and patent information",
    image: lawImage
  },
  {
    title: "Marketing",
    text: "Marketimg translation requires much more creativity than ordinary translation – it does not have to be faithful, but it must be effective.",
    image: marketingImage
  },
  {
    title: "Travel and Hospitality",
    text: "Our experienced travel writers can help you attract customers from this region with engaging articles, destination guides or product descriptions.",
    image: travelHospitalityImage
  }
];

const MEMBERSHIPS_AND_CERTIFICATIONS = [
  {
    alt: "AAA Highest Creditworthiness Rating",
    image: aaa2015Image,
  },
  {
    alt: "GALA Member Company",
    image: galaImage,
    url: "https://www.gala-global.org/"
  },
  {
    alt: "EUATC Network Serbia",
    image: euatcImage,
    url: "https://www.euatc.org/"
  },
  {
    alt: "elia",
    image: eliaImage,
    url: "http://elia-association.org/"
  },
  {
    alt: "ISO 17100",
    image: iso17100Image,
    url: "https://group.bureauveritas.com/"
  },
  {
    alt: "Serbian Association of Translation Companies",
    image: satcImage,
    url: "http://upps.org.rs/"
  }
];

const googleMapContainerStyle = {
  width: '457px',
  height: '405px'
};

const googleMapCenter = {
  lat: 43.316017,
  lng: 21.895553
};

const googleMapOptions = {
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false
}

function LandingPage() {
  const contactRef = useRef(null);

  return (
    <div className="landing-page">
      <header>
        <img src={landingImage} className="logo" alt="Zdravo! Bok! Hello! Hallo!" />
      </header>
      <main>
        <Container fluid>
          <Row>
            <Col sm={12} lg={{ span: 6, offset: 3 }} className="quote-text">
              <ReactMarkdown source={quoteTextYml.text} />
              <Button variant="outline-primary" href="mailto:info@littera-sr.com">Request a quote!</Button>
            </Col>
          </Row>
          <WhyChooseUs />
          <Expertise />
          <MembershipsAndCertifications />
          <ContactUs contactRef={contactRef} />
          <ScrollToTopOrHash contactRef={contactRef} />
        </Container>
      </main>
    </div>
  );
}

function ScrollToTopOrHash({contactRef}) {
  const { hash, pathname } = useLocation();
  const match = useRouteMatch();

  useEffect(() => {
    if (pathname === match.path) {
      if (hash === "#contact") {
        window.scrollTo({
          behavior: "smooth",
          top: contactRef.current.offsetTop - 70
        });
      } else {
        window.scrollTo({
          behavior: "smooth",
          top: 0
        });
      }
    }
  }, [hash, pathname, match.path, contactRef]);

  return null;
}

function WhyChooseUs() {
  return (
    <Row className="about section">
      <Col sm={12} lg={{ span: 6, offset: 3 }}>
        <h2>Why Choose Us</h2>
        <ul>
          <li><FontAwesomeIcon icon={faLongArrowAltRight} className="pointer" /> Over 12 years of experience​​ along with ISO 17100 certification</li>
          <li><FontAwesomeIcon icon={faLongArrowAltRight} className="pointer" /> Top-quality translation at competitive rates</li>
          <li><FontAwesomeIcon icon={faLongArrowAltRight} className="pointer" /> Highly experienced team of translators</li>
          <li><FontAwesomeIcon icon={faLongArrowAltRight} className="pointer" /> Dedicated PMs who speak the languages we translate</li>
          <li><FontAwesomeIcon icon={faLongArrowAltRight} className="pointer" /> Use of all major CAT tools, and QA checkers</li>
        </ul>
      </Col>
    </Row>
  );
}

function Expertise() {
  return (
    <Row className="expertise section">
      <Col sm={12} lg={{ span: 10, offset: 1 }}>
        <h2>Expertise</h2>
        <div className="expertise-boxes">
          {EXPERTISE.map(xp => <ExpertiseBox key={xp.title} title={xp.title} text={xp.text} image={xp.image} />)}
        </div>
      </Col>
    </Row>
  );
}

function ExpertiseBox({title, text, image}) {
  return (
    <div className="expertise-box">
      <div className="expertise-img-container">
        <img src={image} className="expertise-img" alt={title} />
      </div>
      <div className="content">
        <h4 className="expertise-title">{title}</h4>
        <p className="expertise-text">{text}</p>
      </div>
    </div>
  );
}

function MembershipsAndCertifications() {
  return (
    <Row className="memberships-and-certifications section">
      <Col sm={12} lg={{ span: 10, offset: 1 }}>
        <h2>Memberships and Certifications</h2>
        <div className="expertise-boxes">
          {MEMBERSHIPS_AND_CERTIFICATIONS.map(moc => <MembershipOrCertification key={moc.alt} image={moc.image} alt={moc.alt} url={moc.url} />)}
        </div>
      </Col>
    </Row>
  );
}

function MembershipOrCertification({image, alt, url}) {
  if (url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <img src={image} alt={alt} />
      </a>
    );
  } else {
    return <img src={image} alt={alt} />;
  }
}

function ContactUs({contactRef}) {
  return (
    <React.Fragment>
      <Row className="contact" ref={contactRef}>
        <Col><h2 id="contact">Contact Us</h2></Col>
      </Row>
      <Row className="contact">
        <Col className="col-map">
          <LitteraGoogleMap />
        </Col>
        <Col className="col-contact">
          <div className="address">
            <p>
              Littera Sr Translation Agency d.o.o.<br />
              Prijezdina 10/15, 18000 Niš, Serbia<br />
              Tel: +381 69 137 1113<br />
              Fax: +381 18 257 510​<br />
              Email: info@littera-sr.com
            </p>
          </div>
          <div className="contact-form">
          <Form method="POST" data-netlify="true">
            <Form.Control id="contactName" name="contactName" type="text" placeholder="Name *" />
            <Form.Control id="contactEmail" name="contactEmail" type="email" placeholder="Email *" />
            <Form.Control id="contactSubject" name="contactSubject" type="text" placeholder="Subject *" />
            <Form.Control id="contactMessage" name="contactMessage" as="textarea" rows="3" placeholder="Message *" />
            <Button variant="secondary" type="submit">
              Send
            </Button>
            <input type="hidden" name="form-name" value="contact" />
          </Form>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

function LitteraGoogleMap() {
  return (
    <LoadScript googleMapsApiKey="AIzaSyDfCFpoC_odpk-H7Qtg4VdAAmD7xsnM5is">
      <GoogleMap
        mapContainerStyle={googleMapContainerStyle}
        center={googleMapCenter}
        zoom={14}
        options={googleMapOptions}
      >
        <Marker
          position={googleMapCenter}
        >
          <InfoWindow>
            <div className="map-info-window">
              <span>Littera SR</span>
            </div>
          </InfoWindow>
        </Marker>
      </GoogleMap>
    </LoadScript>
  );
}

export { LandingPage };