import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReactMarkdown from 'react-markdown';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';

import './Expertise.scss';
import itTranslationLozalizationImage from '../../media/it-translation-localization.jpg';
import lifeSciencesImage from '../../media/life-sciences.jpg';
import lawImage from '../../media/law.jpg';
import marketingImage from '../../media/marketing.jpg';
import technologyImage from '../../media/technology.jpg';
import travelHospitalityImage from '../../media/travel-hospitality.jpg';

import { ExpertiseSubPage } from './ExpertiseSubPage';

import expertiseIntroYml from '../../netlify-content/expertise/intro.yml';
import languagesYml from '../../netlify-content/expertise/languages.yml';

import itTranslationLocalizationIntroYml from '../../netlify-content/expertise/it-translation-localization/intro.yml';
import itTranslationLocalizationColumn1Yml from '../../netlify-content/expertise/it-translation-localization/column1.yml';
import itTranslationLocalizationColumn2Yml from '../../netlify-content/expertise/it-translation-localization/column2.yml';

import lawIntroYml from '../../netlify-content/expertise/law/intro.yml';
import lawColumn1Yml from '../../netlify-content/expertise/law/column1.yml';
import lawColumn2Yml from '../../netlify-content/expertise/law/column2.yml';

import lifeSciencesIntroYml from '../../netlify-content/expertise/life-sciences/intro.yml';
import lifeSciencesColumn1Yml from '../../netlify-content/expertise/life-sciences/column1.yml';
import lifeSciencesColumn2Yml from '../../netlify-content/expertise/life-sciences/column2.yml';
import lifeSciencesColumn3Yml from '../../netlify-content/expertise/life-sciences/column3.yml';

import marketingIntroYml from '../../netlify-content/expertise/marketing/intro.yml';
import marketingColumn1Yml from '../../netlify-content/expertise/marketing/column1.yml';
import marketingColumn2Yml from '../../netlify-content/expertise/marketing/column2.yml';
import marketingColumn3Yml from '../../netlify-content/expertise/marketing/column3.yml';

import technologyIntroYml from '../../netlify-content/expertise/technology/intro.yml';
import technologyColumn1Yml from '../../netlify-content/expertise/technology/column1.yml';
import technologyColumn2Yml from '../../netlify-content/expertise/technology/column2.yml';
import technologyColumn3Yml from '../../netlify-content/expertise/technology/column3.yml';

import travelHospitalityIntroYml from '../../netlify-content/expertise/travel-hospitality/intro.yml';
import travelHospitalityColumn1Yml from '../../netlify-content/expertise/travel-hospitality/column1.yml';
import travelHospitalityColumn2Yml from '../../netlify-content/expertise/travel-hospitality/column2.yml';
import travelHospitalityColumn3Yml from '../../netlify-content/expertise/travel-hospitality/column3.yml';

function Expertise() {
  const match = useRouteMatch();
  
  return (
    <div className="expertise-pages">
      <Switch>
        <Route path={`${match.path}/it-translation-localization`}>
          <ItTranslationLocalization />
        </Route>
        <Route path={`${match.path}/life-sciences`}>
          <LifeSciences />
        </Route>
        <Route path={`${match.path}/technology`}>
          <Technology />
        </Route>
        <Route path={`${match.path}/law`}>
          <Law />
        </Route>
        <Route path={`${match.path}/marketing`}>
          <Marketing />
        </Route>
        <Route path={`${match.path}/travel-hospitality`}>
          <TravelHospitality />
        </Route>
        <Route path={`${match.path}`}>
          <ExpertisePage />
        </Route>
      </Switch>
    </div>
  );
}

function ExpertisePage() {
  return (
    <Container className="expertise-page page">
      <Row className="intro">
        <Col lg={{ span: 8, offset: 2 }}>
          <h2>Expertise</h2>
          <ReactMarkdown source={expertiseIntroYml.text} />
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 5, offset: 1 }} className="two-col-block-header">
          <h3>Areas of Specialization</h3>
          <AreasOfSpecialization />
        </Col>
        <Col lg={{ span: 5 }} className="two-col-block-header">
          <h3>Languages</h3>
          <ReactMarkdown source={languagesYml.text} />
        </Col>
      </Row>
    </Container>
  );
}

function AreasOfSpecialization() {
  const match = useRouteMatch();

  return (
    <ul>
      <li><Link to={`${match.path}/it-translation-localization`}>IT Translation and Software Localization</Link></li>
      <li><Link to={`${match.path}/life-sciences`}>Life Sciences</Link></li>
      <li><Link to={`${match.path}/technology`}>Technical and Automotive Translation</Link></li>
      <li><Link to={`${match.path}/law`}>Legal Translation</Link></li>
      <li><Link to={`${match.path}/marketing`}>Marketing</Link></li>
      <li><Link to={`${match.path}/travel-hospitality`}>Travel and Hospitality</Link></li>
    </ul>
  )
}

function ItTranslationLocalization() {
  const introText = <ReactMarkdown source={itTranslationLocalizationIntroYml.text} />;

  return (
    <ExpertiseSubPage
      title="IT Translation and Software Localization"
      introImage={itTranslationLozalizationImage}
      introText={introText}
      ctaButtonText="Get in Touch!"
      columns={
        [
          <ReactMarkdown source={itTranslationLocalizationColumn1Yml.text} />,
          <ReactMarkdown source={itTranslationLocalizationColumn2Yml.text} />
        ]
      }
    />
  );
}

function LifeSciences() {
  const introText = <ReactMarkdown source={lifeSciencesIntroYml.text} />;

  return (
    <ExpertiseSubPage
      title="Life Sciences"
      introImage={lifeSciencesImage}
      introText={introText}
      ctaButtonText="Email us for a Quote!"
      columns={
        [
          <ReactMarkdown source={lifeSciencesColumn1Yml.text} />,
          <ReactMarkdown source={lifeSciencesColumn2Yml.text} />,
          <ReactMarkdown source={lifeSciencesColumn3Yml.text} />
        ]
      }
    />
  );
}

function Technology() {
  const introText = <ReactMarkdown source={technologyIntroYml.text} />;

  return (
    <ExpertiseSubPage
      title="Technical and Automotive Translation"
      introImage={technologyImage}
      introText={introText}
      ctaButtonText="Get a Quote"
      columns={
        [
          <ReactMarkdown source={technologyColumn1Yml.text} />,
          <ReactMarkdown source={technologyColumn2Yml.text} />,
          <ReactMarkdown source={technologyColumn3Yml.text} />
        ]
      }
    />
  );
}

function Law() {
  const introText = <ReactMarkdown source={lawIntroYml.text} />;

  return (
    <ExpertiseSubPage
      title="Legal Translation"
      introImage={lawImage}
      introText={introText}
      ctaButtonText="Get a Quote"
      columns={
        [
          <ReactMarkdown source={lawColumn1Yml.text} />,
          <ReactMarkdown source={lawColumn2Yml.text} />
        ]
      }
    />
  );
}

function Marketing() {
  const introText = <ReactMarkdown source={marketingIntroYml.text} />;

  return (
    <ExpertiseSubPage
      title="Marketing Translation"
      introImage={marketingImage}
      introText={introText}
      ctaButtonText="Contact us to find out more"
      columns={
        [
          <ReactMarkdown source={marketingColumn1Yml.text} />,
          <ReactMarkdown source={marketingColumn2Yml.text} />,
          <ReactMarkdown source={marketingColumn3Yml.text} />
        ]
      }
    />
  );
}

function TravelHospitality() {
  const introText = <ReactMarkdown source={travelHospitalityIntroYml.text} />;

  return (
    <ExpertiseSubPage
      title="Travel and Hospitality"
      introImage={travelHospitalityImage}
      introText={introText}
      ctaButtonText="Contact us to find out more"
      columns={
        [
          <ReactMarkdown source={travelHospitalityColumn1Yml.text} />,
          <ReactMarkdown source={travelHospitalityColumn2Yml.text} />,
          <ReactMarkdown source={travelHospitalityColumn3Yml.text} />
        ]
      }
    />
  );
}

export { Expertise };
