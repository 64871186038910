import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReactMarkdown from 'react-markdown';

import './About.scss';
import aboutUsYml from '../netlify-content/about/about-us.yml';
import ourHistoryYml from '../netlify-content/about/our-history.yml';
import whoWeAreYml from '../netlify-content/about/who-we-are.yml';

function About() {
  return (
    <Container className="about-page page">
      <Row className="intro">
        <Col lg={{ span: 8, offset: 2 }}>
          <h2>About Us</h2>
          <ReactMarkdown source={aboutUsYml.text} />
        </Col>
      </Row>
      <Row>
        <Col lg={{ span: 5, offset: 1 }} className="two-col-block-header">
          <h3>Who We Are</h3>
          <ReactMarkdown source={whoWeAreYml.text} />
        </Col>
        <Col lg={{ span: 5 }} className="two-col-block-header">
          <h3>Our History</h3>
          <ReactMarkdown source={ourHistoryYml.text} />
        </Col>
      </Row>
    </Container>
  );
}

export { About };
