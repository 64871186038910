import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import { BrowserRouter as Router, Link, Route, Switch, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitterSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import './App.scss';
import logo from './media/logo.png';

import { About } from './components/About';
import { Careers } from './components/Careers';
import { Expertise } from './components/Expertise/Expertise';
import { LandingPage } from './components/LandingPage';
import { Policy } from './components/Policy';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/careers">
            <Careers />
          </Route>
          <Route path="/expertise">
            <Expertise />
          </Route>
          <Route path="/policy">
            <Policy />
          </Route>
          <Route path="/">
            <LandingPage />
          </Route>
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

function Header() {
  const { hash, pathname } = useLocation();

  function handleClickHome() {
    if (pathname === "/" && hash === "") {
      window.scrollTo({
        behavior: "smooth",
        top: 0
      });
    }
  }

  return (
    <Navbar bg="light" expand="lg" sticky="top">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} width="218" height="43" className="d-inline-block align-top" alt="Littera SR" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link as={Link} to="/" onClick={handleClickHome}>Home</Nav.Link>
            <NavDropdown title="About" id="nav-dropdown">
              <NavDropdown.Item as={Link} to="/about/" eventKey="2.1">About Us</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/policy/" eventKey="2.2">Policy</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Expertise" id="nav-dropdown">
              <NavDropdown.Item as={Link} to="/expertise/" eventKey="3.1">Overview</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/expertise/it-translation-localization" eventKey="3.2">IT Translation and Software Localization</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/expertise/life-sciences" eventKey="3.3">Life Sciences</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/expertise/technology" eventKey="3.4">Technology</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/expertise/law" eventKey="3.5">Law</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/expertise/marketing" eventKey="3.6">Marketing</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/expertise/travel-hospitality" eventKey="3.7">Travel and Hospitality</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Contact" id="nav-dropdown">
              <NavDropdown.Item as={Link} to="/#contact" eventKey="4.1">Contact Us</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/careers/" eventKey="4.2">Careers</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://www.littera-sr.com/blog">Blog</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

function Footer() {
  return (
    <Container fluid className="footer">
      <Row>
        <Col sm={6} lg={{ span: 5, offset: 1 }} className="col-copyright">
          <span>&copy; {(new Date()).getFullYear()} ​Littera Sr Translation Agency d.o.o.</span>
        </Col>
        <Col sm={6} lg={{ span: 5 }} className="col-social">
          <SocialIcon icon={faTwitterSquare} className="twitter" url="https://twitter.com/litterasr" />
          <SocialIcon icon={faLinkedin} className="linkedin" url="https://www.linkedin.com/company/littera-sr/" />
        </Col>
      </Row>
    </Container>
  );
}

function SocialIcon({icon, className, url}) {
  return (
    <div className="icon-wrapper">
      <div className="icon-background" />
      <a href={url} target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={icon} className={`social-icon ${className}`} />
      </a>
    </div>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default App;
