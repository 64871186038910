import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ReactMarkdown from 'react-markdown';

import './Policy.scss';
import policyYml from '../netlify-content/policy/policy.yml';

function Policy() {
  return (
    <Container className="policy-page page">
      <Row>
        <Col lg={{ span: 8, offset: 2 }}>
          <h2>Translation Service Management System Policy</h2>
          <ReactMarkdown source={policyYml.text} />
        </Col>
      </Row>
      <Row className="policy-footer">
        <Col>
          <p className="small-print">
            All employees are obliged to implement, maintain and improve the instituted Translation Service Management System in accordance with the ISO 17100 Standard, in order to provide Organization's continued successful operation.
          </p>
        </Col>
        <Col>
          <p className="small-print">
            This Policy will become effective upon publication, will be printed in English and Serbian, distributed to all employees, as well as posted on the Organization's websi​te. All employees are obliged to act according to and implement the Policy in keeping with their respective authorizations and responsibilities.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export { Policy };
