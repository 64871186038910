import React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

import './ExpertiseSubPage.scss';

function ExpertiseSubPage({title, introImage, introText, ctaButtonText, columns}) {
  return (
    <Container className="expertise-sub-page page">
      <Row>
        <Col lg={{ span: 10, offset: 1 }}>
          <h2>{title}</h2>
        </Col>
      </Row>
      <Row className="intro">
        <Col lg={{ span: 5, offset: 1 }}>
          <img src={introImage} className="intro-image" alt={title} />
        </Col>
        <Col lg={{ span: 5 }}>
          {introText}
        </Col>
      </Row>
      <Row className="cta">
        <Col>
          <Button color="primary" href="mailto:info@littera-sr.com">{ctaButtonText}</Button>
        </Col>
      </Row>
      <Row className="columns" style={columns.length > 2 ? {justifyContent: "center"} : {}}>
        {columns.map((column, index) => (
          <Column
            key={index}
            numColumns={columns.length}
            pos={index}
          >
            {column}
          </Column>
        ))}
      </Row>
      <Row>
        <Col>
          <Link to="/expertise">Find out more about our other Areas of Expertise</Link>
        </Col>
      </Row>
    </Container>
  );
}

function Column({numColumns, pos, children}) {
  let span = 3;
  
  if (numColumns === 1) {
    span = 10;
  } else if (numColumns === 2) {
    span = 5;
  }

  return (
    <Col lg={{ span: span, offset: pos === 0 && numColumns < 3 ? 1 : 0 }}>
      {children}
    </Col>
  );
}

export { ExpertiseSubPage };
